import PropTypes from 'prop-types';
import Avatar from './Avatar.jpeg';

function ButtonPrimary(props) {
    const classNames = [
        "w-full",
        "text-gray-200",
        "block",
        "rounded-lg",
        "text-center",
        "font-medium",
        "leading-6",
        "px-6",
        "py-3",
        "bg-gray-900",
        "hover:bg-black",
        "hover:text-white",
    ];
    return (
        <button onClick={props.action} className={classNames.join(' ')}>
            {props.name}
        </button>
    )
}
ButtonPrimary.propTypes = {
    name: PropTypes.string.isRequired,
}

function ButtonSecondary(props) {
    const classNames = [
        "text-gray-700",
        "hover:text-gray-900",
        "hover:bg-gray-100",
        "rounded",
        "transition",
        "duration-150",
        "ease-in",
        "font-medium",
        "text-sm",
        "text-center",
        "w-full",
        "py-3",
    ];
    return (
        <button onClick={props.action} className={classNames.join(' ')}>
            {props.name}
        </button>
    )
}
ButtonSecondary.propTypes = {
    name: PropTypes.string.isRequired,
}

function PortfolioItem(props) {
    return (
        <div class="w-full flex flex-col justify-center text-center items-center bg-white rounded-lg shadow-md md:flex-row hover:bg-gray-100 md:text-left">
            <div class="w-full mx-8 flex flex-col justify-between p-4 leading-normal">
                <h5 class="mb-2 text-2xl font-bold tracking-tight text-gray-900">{props.title}</h5>
                <p class="mb-3 font-normal text-gray-700">{props.description}</p>
                <ButtonSecondary name="View" action={redirectTo(props.url)} />
            </div>
        </div>
    )
}
PortfolioItem.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
}

function redirectTo(url) {
    return () => window.location.assign(url);
}

export default function Home() {
    return (
        <div class="bg-white relative shadow rounded-lg w-5/6 md:w-5/6 lg:w-4/6 xl:w-3/6 mx-auto">
            <div class="flex justify-center">
                <img
                    class="rounded-full mx-auto absolute -top-20 w-32 h-32 shadow-md border-4 border-white transition duration-200 transform hover:scale-110"
                    src={Avatar}
                    alt="Po-Yang Chen"
                />
            </div>

            <div class="pt-16">
                <h1 class="font-bold text-center text-3xl text-gray-900">
                    Po-Yang Chen
                </h1>
                <p class="text-center text-sm text-gray-900 font-medium mt-3">
                    Management Information System Engineer
                </p>
                <p class="text-center text-sm text-gray-900 font-medium">
                    資訊管理工程師
                </p>
                <div class="my-5 px-6">
                    <ButtonPrimary name="Instagram @poyang31" action={redirectTo('https://instagram.com/poyang31')} />
                </div>
                <div class="flex justify-between items-center my-5 px-6">
                    <ButtonSecondary name="GitHub" action={redirectTo('https://github.com/poyang31')} />
                    <ButtonSecondary name="Email" action={redirectTo('mailto:poyang31@yahoo.com.tw')} />
                </div>

                <div class="w-full">
                    <h3 class="font-medium text-gray-900 text-left px-6">Portfolio 作品集</h3>
                    <div class="mt-5 w-full flex flex-col items-center overflow-hidden text-sm">
                        <PortfolioItem
                            title="Climate"
                            description="臺灣網際網路聲量分析儀 ⚖️"
                            url="https://github.com/poyang31/climate"
                        />
                        <PortfolioItem
                            title="Keisa"
                            description="最簡單的 PHP 框架 🐘"
                            url="https://github.com/poyang31/keisa"
                        />
                        <PortfolioItem
                            title="Amaze"
                            description="利用 OpenAI API 連接的簡易的聊天機器人 💬"
                            url="https://github.com/poyang31/amaze"
                        />
                        <PortfolioItem
                            title="Virgo"
                            description="利用 docker-compose 快速建置 mysql 環境設定 ♍"
                            url="https://github.com/poyang31/virgo"
                        />
                        <PortfolioItem
                            title="Libra"
                            description="MySQL 資料庫備份腳本 ♎"
                            url="https://github.com/poyang31/libra"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}
